export const SET_ACTION_NAME = 'SET_ACTION_NAME';
export const SET_STAT_BONUS = 'SET_STAT_BONUS';
export const SET_DAMAGE_TYPE = 'SET_DAMAGE_TYPE';
export const SET_BONUS_TO_HIT = 'SET_BONUS_TO_HIT';
export const SET_BONUS_TO_DAMAGE = 'SET_BONUS_TO_DAMAGE';
export const SET_MULTI_ATTACK = 'SET_MULTI_ATTACK';
export const SET_RECHARGE_PERCENTILE = 'RECHARGE_PERCENTILE';
export const SET_IS_LEGENDARY = 'IS_LEGENDARY';
export const SET_LEGENDARY_ACTION_COST = 'LEGENDARY_ACTION_COST';
export const SET_DICE = "SET_DICE";
export const SET_DAMAGE_TYPE_OPTIONS = "SET_DAMAGE_TYPE_OPTIONS";
export const SET_SAVE_STAT = "SET_SAVE_STAT";
export const SET_SAVE_DC = "SET_SAVE_DC";
export const SET_IS_AOE = "SET_IS_AOE";
export const SET_AOE_TYPE = "SET_AOE_TYPE";
export const SET_AOE_TYPE_OPTIONS = "SET_AOE_TYPE_OPTIONS";
export const SET_SPELL_OR_ATTACK = "SET_SPELL_OR_ATTACK";
export const SET_DOES_HALF_DAMAGE_ON_FAILURE = "SET_DOES_HALF_DAMAGE_ON_FAILURE";
export const SET_ACTION_CREATION_ERROR_MSG = "SET_ACTION_CREATION_ERROR_MSG";
export const SET_ALL_EFFECTS = "SET_ALL_EFFECTS";
export const SET_ACTION_EFFECTS = "SET_ACTION_EFFECTS";
export const SET_EFFECTS_GOTTEN = "SET_EFFECTS_GOTTEN";