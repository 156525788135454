export const QUANTITY_TEAM1_COMBATANTS = 'QUANTITY_TEAM1_COMBATANTS';
export const QUANTITY_TEAM2_COMBATANTS = 'QUANTITY_TEAM2_COMBATANTS';
export const ADD_TEAM1_COMBATANT = 'ADD_TEAM1_COMBATANT';
export const ADD_TEAM2_COMBATANT = 'ADD_TEAM2_COMBATANT';
export const DELETE_TEAM1_COMBATANT = 'DELETE_TEAM1_COMBATANT';
export const DELETE_TEAM2_COMBATANT = 'DELETE_TEAM2_COMBATANT';
export const SET_TEAM1_COMBATANTS = 'SET_TEAM1_COMBATANTS';
export const SET_TEAM2_COMBATANTS = 'SET_TEAM2_COMBATANTS';
export const SET_ALL_COMBATANTS = 'SET_ALL_COMBATANTS';
export const SET_SIMULATION_RESULTS = 'SET_SIMULATION_RESULTS';
export const SET_ALL_ACTIONS = 'SET_ALL_ACTIONS';

export const ADD_TEAM_COMBATANT = 'ADD_TEAM_COMBATANT'
export const UPDATE_TEAM_COMBATANTS = 'UPDATE_TEAM_COMBATANTS'

export const SET_BATTLE_KEY = 'SET_BATTLE_KEY';
export const SET_BATTLE_KEY_MESSAGE = "SET_BATTLE_KEY_MESSAGE";
export const SET_LOAD_BATTLE_MESSAGE = "SET_LOAD_BATTLE_MESSAGE";
export const SET_RUN_BUTTON_DISABLED = "SET_RUN_BUTTON_DISABLED";